import { router, usePage } from "@inertiajs/react";
import { memo, useContext } from "react";

import BB_Button from "@/Components/BB_Button";
import BB_CompactProductsList from "@/Components/BB_CompactProductsList";
import BB_Hero from "@/Components/BB_Hero";
import BB_ImageWrapper from "@/Components/BB_ImageWrapper";
import BB_Meta from "@/Components/BB_Meta";
import BB_ProductCell from "@/Components/BB_ProductCell";
import BB_QuickSelectCategories from "@/Components/BB_QuickSelectCategories";
import { SizeContext } from "@/ContextProviders/SizeProvider";
import ExploreIcon from "@/Icons/compass.svg?react";
import LogoLarge from "@/Icons/logo_large.svg?react";
import AuthenticatedLayout from "@/Layouts/AuthenticatedLayout";
import GuestLayout from "@/Layouts/GuestLayout";
import BB_Category from "@/types/BB_Category";
import BB_Product from "@/types/BB_Product";
import BB_User from "@/types/BB_User";
import { generateMailtoLink } from "@/Utils/MailtoGenerator";

export type HomePageProps = {
	auth: { user: BB_User | null };
	popular_products: BB_Product[];
	popular_categories: BB_Category[];
	latest_products_for_widget: BB_Product[];
};

type AnyImage = {
	url: string;
	width: number;
	height: number;
	alt: string;
};

const pressImages: AnyImage[] = [
	{
		url: "https://images.bestell.bar/images/ps5bot/TZ_Logo.webp",
		width: 382,
		height: 500,
		alt: "TZ"
	},
	{
		url: "https://images.bestell.bar/images/ps5bot/computerbild-logo.webp",
		width: 300,
		height: 147,
		alt: "TZ"
	},
	{
		url: "https://images.bestell.bar/images/ps5bot/ntv.webp",
		width: 300,
		height: 300,
		alt: "TZ"
	},
	{
		url: "https://images.bestell.bar/images/ps5bot/derspiegel-logo.webp",
		width: 372,
		height: 156,
		alt: "TZ"
	},
	{
		url: "https://images.bestell.bar/images/ps5bot/PC_Games_logo.webp",
		width: 565,
		height: 200,
		alt: "TZ"
	}
];

const HomePage = (props: HomePageProps) => {
	const { isCompact } = useContext(SizeContext);
	const user: BB_User | undefined = usePage().props.auth?.user;

	const Layout = props.auth.user ? AuthenticatedLayout : GuestLayout;

	return (
		<Layout>
			<BB_Meta
				title="Bestell.bar: Benachrichtigungen zur Verfügbarkeit von Produkten"
				description="Wir benachrichtigen dich sofort, sobald dein gewünschtes Produkt bestellbar ist: Egal ob Grafikkarten, Konsolen oder Sammelkarten - nie wieder leer ausgehen!"
				keywords="Bestell.bar, Benachrichtigung, Verfügbarkeit, Produkte, Grafikkarten, Konsolen, Sammelkarten"
				url={route("home", {}, true)}
			/>

			{!user && <BB_Hero />}

			<div className="mt-3 flex flex-col gap-4 overflow-visible pt-4 lg:flex-row lg:gap-8">
				<div className="flex flex-col lg:w-2/3">
					<h2 className="mb-4 ps-3 text-left text-xl font-bold sm:ps-2">
						Beliebte Produkte
					</h2>
					<div className="bg-background-100 dark:bg-dark-background-300 rounded-none px-3 py-6 shadow-lg sm:rounded-lg sm:px-6">
						<div className="xs:grid-cols-2 3xl:grid-cols-5 grid grid-cols-1 gap-4 sm:grid-cols-2 xl:grid-cols-4">
							{props.popular_products.map((product, index) => (
								<BB_ProductCell
									key={product.id}
									index={index}
									product={product}
									isCompact={isCompact}
								/>
							))}
						</div>

						<BB_Button
							onClick={() => router.visit(route("explore"))}
							type="secondary"
							icon={ExploreIcon}
							iconClassName={"h-5 w-5 mr-2 pointer-events-none"}
							title={"Weitere Produkte entdecken"}
							buttonClassName="mt-4 me-auto w-70"
						/>
					</div>

					<h2 className="mt-4 mb-4 ps-3 text-left text-xl font-bold sm:ps-2">
						Kein Release mehr verpassen
					</h2>
					<div className="bg-background-100 dark:bg-dark-background-300 flex flex-col rounded-none px-3 py-6 text-pretty hyphens-auto shadow-lg sm:rounded-lg sm:px-6">
						<p className="text-justify">
							Wir listen alle möglichen spannenden Produkte, sogar noch bevor deren
							Existenz offiziell bestätigt wurde. Abonniere die Benachrichtigungen der
							Produkte, für die du dich interessierst! So stellst du sicher, dass du
							kein Release mehr verpasst und optimierst deine Chancen, das Produkt
							kaufen zu können, sollte die Nachfrage das Angebot übersteigen. Falls du
							dennoch leer ausgehst, lasse die Benachrichtigung einfach aktiv - wir
							überwachen die Verfügbarkeit von Produkten auch nach deren
							Veröffentlichung - mindestens solange, bis genügend Kontingent für alle
							vorhanden ist.
						</p>

						<LogoLarge className="mx-auto my-4 mt-6 max-w-60" />

						<p className="text-justify">
							Die Idee für Bestell.bar ist 2020 entstanden, als wir den massiven
							Andrang auf die PlayStation 5 beobachtet haben. Viele Gamer mussten
							hunderte Euro über UVP an Reseller bezahlen, um die Konsole zu kaufen.
							Unsere Benachrichtigungen ermöglichten zahllosen Interessenten eine PS5
							für einen fairen Preis direkt bei den Händlern zu ergattern. Mit der{" "}
							<a href={"https://www.foraum.de"} target={"_blank"} rel="noreferrer">
								Foraum App
							</a>{" "}
							bildete sich eine gigantische Community, die sich zusammen auf die Jagd
							nach den Konsolen begab. Schätzungsweise haben unsere Nutzer alleine mit
							der PS5 insgesamt weit über 50 Millionen Euro gespart 🤯
						</p>

						<div className={"my-8 px-4 text-center sm:px-8"}>
							<i>bekannt aus der Presse:</i>
							<div className={"my-4 flex flex-wrap justify-center gap-3"}>
								{pressImages.map((image, index) => (
									<BB_ImageWrapper
										key={image.url + "_" + index.toString()}
										bb_image={{
											url: image.url,
											height: image.height,
											width: image.width,
											trimmed: 0
										}}
										suffix="?class=3"
										className="max-h-14 w-auto rounded-md select-none"
										alt={image.alt}
									/>
								))}
							</div>
							<div className={"text-pretty"}>
								<p>
									&quot;So verpassen Sie in Zukunft kein Angebot mehr&quot; -
									Julian Neubauer, ComputerBild.de
								</p>
								<p>
									&quot;Erhöht die Chancen, eine Konsole zu erhalten&quot; - Ömer
									Kayali, tz.de
								</p>
							</div>
						</div>

						<p className="text-justify">
							Seitdem ist viel passiert: Wir haben uns Heise angeschlossen, um
							Rückenwind für unsere Ideen zu bekommen. Dadurch konnten wir auch
							Bestell.bar für euch entwickelt. Unser Instinkt für &quot;die Jagd&quot;
							von damals ist geblieben. Mit Bestell.bar verfolgen wir Releases von
							begehrten Grafikkarten, Collector Editions, Sammelkarten und vielem mehr
							- einfach allem, wovon es möglicherweise zeitweise nicht genug gibt,
							oder generell Releases, die man so oder so auf keinen Fall verpassen
							möchte. Ein Produkt fehlt?&nbsp;
						</p>
						<a
							className={"mt-3 flex-1 text-center"}
							href={generateMailtoLink(
								"Produkt fehlt:",
								"Produktname:\n\nFalls vorhanden, Händlerlinks zum Überwachen (z. B. amazon.de/dp/...):\n\nIst das Produkt aktuell überall ausverkauft? (ja/nein)\n\nBeste Grüße"
							)}>
							Gib uns Bescheid!
						</a>
					</div>
				</div>

				<div className="top-20 flex w-full flex-col lg:sticky lg:h-fit lg:w-1/3">
					<h2 className="mb-4 ps-3 text-left text-xl font-bold sm:ps-2">
						Beliebte Kategorien
					</h2>
					<div className="bg-background-100 dark:bg-dark-background-300 rounded-none px-3 py-6 shadow-lg sm:rounded-lg sm:px-6">
						<BB_QuickSelectCategories categories={props.popular_categories} />
					</div>

					<h2 className="my-4 ps-3 text-left text-xl font-bold sm:ps-2">Neue Produkte</h2>
					<div className="bg-background-100 dark:bg-dark-background-300 rounded-none px-3 py-6 shadow-lg sm:rounded-lg sm:px-6">
						<BB_CompactProductsList
							products={props.latest_products_for_widget}
							direction="column"
						/>
					</div>
				</div>
			</div>
		</Layout>
	);
};

export default memo(HomePage);
